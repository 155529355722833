<template>
  <v-card v-if="!loading & !isComplete" elevation="2">
    <v-toolbar color="primary" dark flat>
      <span class="text-h6 white--text">新規お問合せ</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-form class="px-4 " style="overflow-y:auto">
      <v-container>
        <v-text-field v-model="sendData.name" outlined maxlength="50" label="お名前（必須）" :rules="rules"></v-text-field>
        <v-text-field v-model="sendData.email" outlined maxlength="50" label="連絡用メールアドレス（任意）"
          hint="※登録したメールアドレスと連絡用のメールアドレスが異なる場合"></v-text-field>
        <p class="text--secondary mb-2">お問い合わせの種類</p>
        <v-radio-group v-model="sendData.type" column mandatory class="pl-5 mt-0">
          <v-radio label="アカウントや請求について" color="primary" value="0"></v-radio>
          <v-radio label="データ容量の追加について" color="primary" value="1"></v-radio>
          <v-radio label="バグや不具合について" color="primary" value="2"></v-radio>
          <v-radio label="その他" color="primary" value="3"></v-radio>
        </v-radio-group>
        <v-textarea v-model="sendData.details" outlined clearable name="input-7-4" label="お問い合わせ内容（必須）"
          :rules="rules"></v-textarea>
        <v-row class="justify-center">
          <v-col cols="4">
            <v-btn color="primary" block class="white--text mt-0 mb-2" width="100px" @click="createInquiry()">
              送信
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
  <v-card v-else-if="isError">
    <DialogError v-on:closeErrorEmit="close" text="エラーが発生し、お問い合わせの送信が完了しませんでした。" />
  </v-card>
  <v-card v-else-if="isComplete">
    <DialogComplete v-on:closeEmit="close" text="送信が完了しました。担当者からメールが来ます。" />
  </v-card>
  <v-card v-else color="primary" dark>
    <v-card-text>
      送信中...
      <br>
      <v-progress-linear indeterminate color="white"></v-progress-linear>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
const DialogComplete = () => import('@/components/DialogComplete.vue');
const DialogError = () => import('@/components/DialogError.vue');

export default {
  name: 'Inquiry',
  components: {
    DialogComplete,
    DialogError
  },
  data: () => ({
    loading: false,
    isError: false,
    isComplete: false,
    sendData: {
      name: null,
      email: null,
      type: null,
      details: null
    },
    rules: [
      v => !!v || "必須項目です。"
    ],
  }),
  methods: {
    close() {
      this.loading = false;
      this.isError = false;
      this.isComplete = false;
      this.sendData.name = null;
      this.sendData.email = null;
      this.sendData.type = "0";
      this.sendData.details = null;
      this.$emit('closeEmit');
    },
    async createInquiry() {
      this.loading = true;
      if (!this.sendData.email) {
        this.sendData.email = this.$store.state.user_model.userData.userId;
      }
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/inquiry`,
          this.sendData, {
          headers: {
            "X-Api-Key": process.env.VUE_APP_KEY,
            "SessionId": this.$store.state.auth.login.sessionId,
            "Content-Type": "application/json"
          }
        }
        );
        const id = response.data.results;
        this.loading = false;
        this.isComplete = true;
        this.$emit('createInquiryEmit', id, this.sendData);
      } catch (error) {
        console.log(error);
        this.isError = true;
      }
    }
  },
};
</script>
